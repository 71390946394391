import { render, staticRenderFns } from "./topicList.vue?vue&type=template&id=b7902dea&scoped=true"
import script from "./topicList.vue?vue&type=script&lang=js"
export * from "./topicList.vue?vue&type=script&lang=js"
import style0 from "./topicList.vue?vue&type=style&index=0&id=b7902dea&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b7902dea",
  null
  
)

export default component.exports